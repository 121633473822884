import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect } from 'react'

export interface Props {
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({ languagePrefix }: Props) {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    script.innerHTML = `
    (function (i, s, o, g, r, a, m) {
        i['SBSyncroBoxParam'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://cdn.simplebooking.it/search-box-script.axd?IDA=8691','SBSyncroBox');
    SBSyncroBox({
        CodLang: '${languagePrefix.toLocaleUpperCase()}',
        NumberOfMonthsVertical: '2',
        
        Styles: {
          Theme: 'light-pink',
          CustomBGColor: 'transparent',
          CustomButtonColor: '${theme.colors.variants.neutralDark2}',
          CustomButtonBGColor: '${theme.colors.variants.primaryLight}',
          CustomButtonHoverBGColor: '#DDAD25',
          CustomFieldBackgroundColor: 'transparent',
          CustomLinkColor: '${theme.colors.variants.neutralDark2}',
          CustomIconColor: '${theme.colors.variants.neutralLight4}',
          CustomSelectedDaysColor: '${theme.colors.variants.neutralDark2}',
          CustomBoxShadowColor: 'transparent',
          CustomBoxShadowColorFocus: 'transparent',
          CustomBoxShadowColorHover: 'transparent',
          CustomIntentSelectionColor: '${theme.colors.variants.primaryLight}',
          CustomIntentSelectionDaysBGColor: '${theme.colors.variants.primaryLight
      }',
          CustomAccentColor: '${theme.colors.variants.primaryLight}',
          CustomAccentColorHover: '${theme.colors.variants.neutralDark1}',
          CustomColor: '${theme.colors.variants.neutralLight4}',
          CustomColorHover: '${theme.colors.variants.primaryLight}',
          CustomLabelColor: '${rgba(theme.colors.variants.neutralDark2, 0.4)}',
          CustomLabelHoverColor: '${theme.colors.variants.primaryLight}',
          CustomCalendarBackgroundColor: '${theme.colors.variants.neutralDark2
      }',
          CustomWidgetBGColor: '${theme.colors.variants.neutralDark2}',
          CustomWidgetElementHoverBGColor: '${theme.colors.variants.neutralDark4
      }',
          CustomWidgetElementHoverColor: '${theme.colors.variants.neutralLight4
      }'
        },

        Labels: {
          SelectProperty: {'EN': 'Destination', 'IT': 'Destinazione'},
          AllProperties: {'EN': 'All Properties', 'IT': 'Seleziona struttura'},
          CheckinDate: {'EN': 'Check in', 'IT': 'Arrivo'},
          CheckoutDate: {'EN': 'Check out', 'IT': 'Partenza'},
          NumPersons: {'EN': 'Guests','IT': 'Ospiti'},
          NumAdults: {'EN': 'Adults', 'IT': 'Adulti'},
          NumKids: {'EN': 'Kids', 'IT': 'Bambini'},
          PromoCode: {'EN': 'Promo Code', 'IT': 'Codice Promo'},
          ModCancReservation: {'EN': 'modify/cancel reservation', 'IT': 'modifica/cancella prenotazione'},
          CheckAvailability: {'EN': 'Book now', 'IT': 'Prenota ora'},
        }
        
    });
    `

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Container dial={4} row space="between">
      <Widget id="sb-container" />
    </Container>
  )
})
const Container = styled(FlexBox)`
  width: 100%;
  height: 7rem;
  position: absolute;
  top: calc(100vh - 7rem);
  right: 0;
  left: 0;
  z-index: 5;

  #sb-container {
    @media (min-width: 1441px) {
      max-width: 80.625rem;
    }

    .sb-custom-link-color {
      &:hover {
      }
    }
    .sb__guests-children-age-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    }
    .sb__guests-children-age-select {
      color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
      box-shadow: 0 0 0 1px
        ${({ theme }) => theme.colors.variants.neutralLight4} !important;

      &:focus {
        box-shadow: 0 0 0 1px
          ${({ theme }) => theme.colors.variants.neutralLight4} !important;
      }
    }
    .sb__footer-promo-label {
    }
    .sb [type='text'] {
      height: 2.25rem;
      background: ${({ theme }) =>
    theme.colors.variants.neutralLight4} !important;
      border-radius: 0;
      box-shadow: none !important;
      color: ${({ theme }) => theme.colors.variants.neutralDark1} !important;
      padding: 0 0.75rem;
    }
  }
`

const Widget = styled.div`
  width: auto;

  .sb {
    display: flex !important;
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    padding: 0 !important;
    position: relative;
    width: 100%;
  }
  .sb__form {
    padding-left: 10.556vw;
    background: ${({ theme }) =>
    theme.colors.variants.neutralLight4} !important;
    align-items: center;
    width: 100%;
  }
  .sb__property {
    display: none;
  }
  .sb button,
  .sb input {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
  }
  .sb__btn {
    border-radius: 0;
  }
  .sb select {
    box-shadow: none !important;
    border-radius: 0;
    padding: 0.125rem 0.75rem 0.25rem 1.125rem;
  }
  .sb__form-field-label {
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.1875rem;
    margin-bottom: 0.5rem;
  }
  .sb__form-field {
    cursor: pointer;
    padding-bottom: 0.1875rem;
    margin-right: 0;
  }

  .sb__dates {
    display: flex;
    flex-grow: 0;
    width: auto !important;

    .sb__form-field {
      width: auto !important;
      margin-right: 1.875rem;
      .sb__form-field-label {
        padding: 0 !important;
      }
      .sb__form-field-input {
        padding: 0 !important;
        display: flex;
        align-items: center;
        position: relative;
        height: 1.5rem !important;
        &:before {
          content: '';
          margin-right: 0.75rem;
          height: 1.875rem;
          width: 1.5rem;
          bottom: auto;
          top: auto;
          right: auto;
          left: auto;
          position: static;
          display: block;
          border: none;
          background: url('/calendar.svg') no-repeat left center;
        }
        .sb__form-field-date-wrapper {
          line-height: 1.5rem;
        }
      }
      &:first-of-type .sb__form-field-input {
        padding-right: 0 !important;

        .sb__form-field-date-wrapper {
          border-right: 0.125rem solid
            ${rgba(theme.colors.variants.neutralDark2, 0.1)};
        }

        .sb__form-field-month-year {
          padding-right: 2vw;

          @media (min-width: 1441px) {
            padding-right: 2.5rem;
          }
        }
      }
    }

    @media (min-width: 2400px) {
      min-width: 36.806vw;
    }
  }
  .sb__form-field-date-number {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.25rem;
    font-size: 1.0625rem !important;
    line-height: 1.875rem !important;
    font-weight: 600;
  }
  .sb__form-field-weekday,
  .sb__form-field-rooms {
    display: none;
  }
  .sb__form-field-month-year,
  .sb__form-field-guests {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    font-size: 1.0625rem !important;
    font-weight: 600;
    line-height: 1.875rem !important;
    text-transform: lowercase;
  }
  .sb__form-field-guests {
    max-width: 7.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sb__form-field-input {
    height: auto !important;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0 !important;
    &:hover {
      box-shadow: none;
    }
  }
  .sb__guests-rooms {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    width: auto !important;
    min-width: 255px;
    margin: 0 !important;
    padding: 0 !important;
    &:before {
      content: '';
      position: absolute;
      top: 1rem;
      bottom: 1rem;
      left: 0;
      width: 0.125rem;
      background: ${rgba(theme.colors.variants.neutralDark2, 0.1)};
    }

    .sb__form-field {
      .sb__form-field-label {
        padding: 0 2vw !important;
      }
      .sb__form-field-input {
        padding: 0 2vw !important;
        display: flex;
        align-items: center;
        height: 1.5rem !important;
        &:before {
          content: '';
          margin-right: 0.75rem;
          height: 1.875rem;
          width: 1.5rem;
          display: block;
          background: url('/guests.svg') no-repeat left center;
        }
      }
    }
  }
  .sb__form-field-icon {
    .icon {
      display: none;
    }
  }
  .sb__guests {
    min-width: 300px;
    box-shadow: none !important;
    border-radius: 0;
    margin-top: 1.25rem;
    left: 0 !important;

    .sb__panel-actions {
      button.sb__btn.sb__btn--secondary.sb-custom-widget-element-hover-color.sb-custom-widget-element-hover-bg-color {
        color: ${({ theme }) => theme.colors.variants.neutralLight4}!important;
        border: 0.0625rem solid
          ${({ theme }) => theme.colors.variants.neutralLight4};
        padding-top: 0.4375rem;
        padding-bottom: 0.4375rem;
        &:hover {
          color: ${({ theme }) =>
    theme.colors.variants.neutralLight4}!important;
        }
      }
    }
  }
  .sb__guests-room-header {
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .sb__guests-room {
    .sb__guests-adults.sb-custom-label-hover,
    .sb__guests-children.sb-custom-label-hover {
      color: ${({ theme }) => theme.colors.variants.neutralLight4}!important;

      span.sb-custom-label-hover-color:hover {
        color: ${({ theme }) => theme.colors.variants.neutralLight4}!important;
      }
    }

    span.sb__guests-adults-label.sb-custom-label-hover-color,
    span.sb__guests-children-label.sb-custom-label-hover-color {
      color: ${({ theme }) => theme.colors.variants.neutralLight4}!important;
    }
  }

  .sb__guests-room-remove {
    .icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-top: -0.5rem;
    }
  }
  .sb__btn--verify {
    width: auto;
    min-width: 16.25rem;
    height: 7rem !important;
    font-size: 1.0625rem !important;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    line-height: 1.25rem;
    border-radius: 0;
    padding: 0 !important;
    margin-top: 0;
    margin-left: auto;
    text-align: center;
  }

  .sb__footer {
    display: none;
  }

  .sb__calendar {
    box-shadow: none;
    border-radius: 0;
    margin-top: 1.25rem;
    z-index: 3;
  }
  .sb__calendar-month-name {
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 1rem;
    text-transform: capitalize;
  }
  .sb__calendar-btn {
    box-shadow: none !important;
    border-radius: 0;
  }
  .sb__calendar-btn-icon {
    width: 1.5rem;
    height: 1.5625rem;
    .icon {
      width: 2.75rem;
      height: 2.75rem;
      margin: -0.625rem 0 0 -0.5625rem;
      fill: ${({ theme }) => theme.colors.variants.neutralLight4}!important;
    }
  }
  .sb__calendar-day {
    border-radius: 0;
  }

  .number-of-months-vertical--right .sb__calendar {
    right: initial;
  }
  .sb-open-top .sb__calendar {
    margin-bottom: 1.875rem;
  }

  .sb-open-top .sb__guests {
    margin-bottom: 0;
  }
`
